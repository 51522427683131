<template>
  <b-card class="h-100" footer-tag="footer" footer-bg-variant="white">
    <router-link :style="notClickable ? 'pointer-events: none' : ''" :to="`/${$t('navBar.link.Overview')}/${carDetail.ListingId}`">
      <!-- <div
        style="height:194px;"
        class="d-flex align-items-top justify-content-center rounded-top border-bottom"
        v-if="imageLink"
      >
        <b-img
          :src="imageLink"
          alt=""
          @error="imageError"
          blank-color="black"
          class="carImg"
          rounded="top"
          fluid-grow
        />
      </div> -->
      <div
        v-if="imageLink"
        class="box"
        :style="{
          'background-image': 'url(' + $store.getters.hostURL + carDetail.Image + ')',
        }"
      />
      <div
        style="height: 194px"
        class="d-flex align-items-top justify-content-center rounded-top border-bottom"
        v-else
      >
        <b-img
          :src="require('@/assets/img/nophoto.jpg')"
          alt=""
          blank-color="black"
          class="carImg"
          rounded="top"
          fluid-grow
        />
      </div>
    </router-link>
    <span class="h5 comingSoon text-muted" v-if="!imageLink">{{
      $t("global.label.comingsoon")
    }}</span>
    <div
      class="statusBox bg-white border border-primary rounded text-center"
      v-if="carDetail.IsReserved"
    >
      <span class="text-primary" style="font-size: 12px; font-weight: bold">
         {{ $t('Overview.Reserved') }}
      </span>
    </div>
    <div class="rounded-circle bg-white border heartCircle" v-if="wishlist">
      <HeartIcon v-if="fav" @click="setWishList()" fill-color="#DB4437" />
      <HeartOutlineIcon v-if="!fav" @click="setWishList()" />
    </div>
    <div class="pt-3">
      <div class="px-3">
        <router-link
          :style="notClickable ? 'pointer-events: none' : ''"
          :to="`/${$t('navBar.link.Overview')}/${carDetail.ListingId}`"
        >
          <p class="cardTitle m-0">
            {{ carDetail.Year }} {{ carDetail.Make }}
            {{ carDetail.Model }}
          </p>
        </router-link>
      </div>
      <div class="px-3">
        <p class="small m-0" style="display: inline-block">
          <b v-if="carDetail.Trim"> {{ carDetail.Trim }} </b>
          <span v-else> [trim] </span>
        </p>
      </div>

      <div class="mb-2 px-3 pt-2 detailText text-muted" style="height: 30px">
        <div>
          <span v-if="carDetail.Kilometers">{{ carDetail.Kilometers }}</span>
          <span v-else> [Mileage] </span>
          <span v-if="carDetail.Transmission">
            <span class="divider">|</span> {{ carDetail.Transmission }}</span
          >
          <span v-else> [Transmission] </span>
          <span v-if="carDetail.Country">
            <span class="divider">|</span> {{ carDetail.Country }}</span
          >
          <span v-else> [Country] </span>
        </div>
      </div>

        <div class="mx-3 pb-3">
          <span
            style="font-size: 18px; font-weight: bold; white-space: nowrap"
            class="text-primary pb-0"
          >
            {{ priceData }}
          </span>
          <!-- <span
            style="font-size: 18px; font-weight: bold; white-space: nowrap"
            class="text-success pb-0"
            v-if="carDetail.Status === 'Pending Sales'"
          >
            RESERVED
          </span>
          <span
            style="font-size: 18px; font-weight: bold; white-space: nowrap"
            class="text-danger pb-0"
            v-if="carDetail.Status === 'Confirmed Sales'"
          >
            SOLD
          </span> -->
        </div>
        <p class='mx-3 px-2 mt-0 py-2 labelText position-relative' style="z-index:2">
          {{ $t("carcard.label.delivery") }} > {{ carDetail.DeliveryCountry || $store.state.search.destinationCountry.Name }}<br/>
          {{
            RSVPTotalFeeId === 44 ?  $t("carcard.help.including") :
            RSVPTotalFeeId === 33 ?  $t("carcard.help.delivery") :
            includeFees ? $t("carcard.help.including") : $t("carcard.help.delivery")
          }}
        </p>
        <div class="py-2 text-right" v-show="buttonType" v-if="!wishlist && !reservation">
          <!-- Reservation Buttons -->
          <b-button
            v-if="buttonType == 'reserve' && carDetail.IsReserved"
            pill
            variant="secondary"
            class="cardButtonLabel"
            disabled
            :style="notClickable ? 'pointer-events: none' : ''"
            :to="{
              name: 'Reservation',
              params: { id: carDetail.ListingId },
            }"
            >{{ $t("carcard.button.reserveNow") }}</b-button
          >

          <!-- Confirm Now Buttons -->
          <b-button
            v-if="
              buttonType == 'confirm' && carDetail.CanConfirm && !carDetail.IsConfirmed
            "
            pill
            variant="warning"
            class="cardButtonLabel"
            >{{ $t("carcard.button.confirmNow") }}</b-button
          >
          <b-button
            v-if="
              buttonType == 'confirm' && !carDetail.CanConfirm && !carDetail.IsConfirmed
            "
            pill
            variant="secondary"
            class="cardButtonLabel"
            disabled
            >{{ $t("carcard.button.confirmNow") }}</b-button
          >
          <!-- Confirmed Buttons -->
          <b-button
            v-if="buttonType == 'confirm' && carDetail.IsConfirmed"
            pill
            disabled
            variant="danger"
            class="cardButtonLabel"
            >{{ $t("carcard.button.sold") }}</b-button
          >
        </div>
    </div>
    <template #footer v-if="status && !wishlist && !reservation">
      Status:
      <span class="text-primary">
        {{ carDetail.Status }}
        <b-icon :id="iconId + 'stat'" icon="info-circle"></b-icon>
        <b-tooltip
          :target="iconId + 'stat'"
          triggers="hover"
          placement="top"
          variant="dark"
        >
          <p class="h6">
            {{ carDetail.Status }}
          </p>
          <p class="small">

          </p>
        </b-tooltip>
      </span>
    </template>
  </b-card>
</template>
<script>
import HeartIcon from "vue-material-design-icons/Heart.vue";
import HeartOutlineIcon from "vue-material-design-icons/HeartOutline.vue";
export default {
  props: {
    carDetail: {
      type: Object,
      required: true,
    },
    price: {
      type: Object,
      required: false,
    },
    buttonType: {
      type: String,
    },
    wishlist: {
      type: Boolean,
      default: false,
    },
    reservation: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Boolean,
      default: false,
    },
    notClickable: {
      type: Boolean,
      default: false,
      required: false
    },
    includeFees: {
      type: Boolean,
      required: false,
    },
    RSVPTotalFeeId: {
      type: Number,
      required:false
    }
  },
  components: {
    HeartIcon,
    HeartOutlineIcon,
  },
  data() {
    return {
      confirmed: false,
      canReserve: true,
      reserved: false,
      sash: require("@/assets/img/sash_tr_blk.png"),
      fav: this.carDetail.IsWishList,
      imageLink: this.carDetail.Image,
    };
  },
  computed: {
    iconId() {
      return "delivery_" + this.carDetail.ListingId;
    },
    priceData() {
      try {
        let price = 0,
        currency = "cad";
        if (this.price.Amount && this.price.CurrencyCode) {
          price = this.price.Amount;
          currency = this.price.CurrencyCode;
        } else if (this.carDetail.Price.Amount && this.carDetail.Price.CurrencyCode) {
        price = this.carDetail.Price.Amount;
        currency = this.carDetail.Price.CurrencyCode;
      }
      return this.currencyFormatter(price, currency);
    } catch(err){
      console.log(err)
    }
    },
  },
  methods: {
    setWishList() {
      if (this.$store.getters.user) {
        this.$store.dispatch("updateWishList", this.carDetail.ListingId);
        this.fav = !this.fav;
      } else {
        this.$bvModal.show("signin");
      }
    },
    imageError() {
      this.imageLink = null;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    currencyFormatter(value, currency) {
      try {
        return new Intl.NumberFormat(this.$store.getters.currencyCulture, {
          style: "currency",
          currency: currency,
          minimumFractionDigits: value % 1 != 0 ? 2 : 0,
        }).format(value);
      } catch (e) {
        return "";
      }
    },
    formatDistance(num) {
      let x = new Number(num);
      x = Math.ceil(x).toLocaleString(this.$i18n.locale);
      return x;
    },
  },
};
</script>
<style scoped lang="scss">
.box {
  width: 100%;
  height: 240px !important;
  background-size: cover;
  background-position: center center;
  border-radius: 3px 3px 0 0;
}
.carImg {
  max-width: 100%;
  max-height: 194px;
}
.card {
  /* width: 345px !important ; */
  /* height: 424px !important; */
  padding: 0px !important;
}
.card-body {
  padding: 0px !important;
}
.cardTitle {
  font-size: 14px;
  font-weight: bold;
  color: black;
}
.labelText {
  background-color: #FFC32487;
  color:black;
  /* color: #828282 !important; */
  font-size: 11px;
}
.detailText {
  font-size: 0.8em;
  font-weight: bold;
}
.cardButtonLabel {
  font-size: 12px;
  font-weight: bold;
  width: 100%;
}
.heartCircle {
  height: 39px !important;
  width: 39px !important;
  box-shadow: 0 2px 7px rgb(0 0 0 / 0.2);
  position: absolute;
  padding: 0 !important;
  right: 0;
  margin-top: -20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  cursor: pointer;
}
.statusBox {
  height: 29px !important;
  width: 80px !important;
  box-shadow: 0 2px 7px rgb(0 0 0 / 0.2);
  position: absolute;
  padding: 0 !important;
  margin-top: -185px;
  margin-left: 255px;
}
.comingSoon {
  position: absolute;
  padding: 0 !important;
  margin-top: -185px;
  margin-left: 10px;
}
.divider {
  color: #cdcdcd;
  color: #cdcdcd;
  height: 16px;
  margin: 0 8px;
}
</style>
